#faq {
  padding-top: 80px !important;
  background: #110a11;
}
#faq h2 {
  margin-bottom: 60px;
}

.faq {
  overflow: hidden;
}
.faq_title {
  border: 6px solid #333;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  background: #111;
  text-align: left !important;
}

.faq_title img {
  align-self: center;
}

.faq_title:hover {
  background: #1a1a1a !important;
  transition: all 0.2s;
}
.faq_title p {
  margin-bottom: 0 !important;
  padding-right: 20px;
  font-size: 18px;
}
.answer {
  height: 0;
  font-size: 18px;
  transition: all 0.2s;
}
.answer.active {
  height: auto;
  padding: 20px 30px 30px;
  transition: all 0.2s;
}

.answer ol {
  list-style: decimal;
  padding-left: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  #faq {
    padding-top: 60px;
  }
  #faq h2 {
    margin-bottom: 40px;
  }
  .faq_title {
    padding: 14px 20px;
    border: 2px solid #333;
  }
  .faq_title p {
    font-size: 16px;
  }
  .faq_title img {
    width: 14px;
  }

  .answer {
    font-size: 16px;
  }
  .answer.active {
    padding: 10px 0 40px;
  }
}
