.checkbox {
  justify-content: center;
  align-items: center;
}

.checkbox input {
  margin-right: 15px;
  display: inline-block;
}

.checkbox label {
  display: inline-block;
}
