#team h2 {
  text-align: center;
  margin-bottom: 40px;
}
#team > div {
  max-width: 890px !important;
}
#team > div > p {
  text-align: center;
  margin-bottom: 60px;
}

#team h3 {
  font-size: 36px !important;
  margin-bottom: 30px;
}
#team h3 img {
  display: inline-block;
  margin-left: 8px;
  position: relative;
  top: 1px;
}
#team #members > div {
  background: #1e111e;
  padding: 40px;
  border-radius: 7px;
}
#team #members > div > img {
  border: 7px solid #2d2d2d;
  border-radius: 7px;
}
#team .data span {
  color: #ff0000;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 24px;
  display: inline-block;
}
#team .data {
  text-align: center;
  padding-top: 30px;
}
#team .data a {
  display: inline-block;
  margin: 0 8px;
  transition: all 0.2s;
}
#team .data a:hover {
  opacity: 0.8;
  transition: all 0.2s;
}
#team .data a img {
  max-height: 20px;
}

#logos_partners {
  padding-top: 120px;
}
#logos_partners h3 {
  text-align: center;
  margin-bottom: 80px;
}
#logos_partners img {
  max-height: 70px;
}
#logos_partners > div {
  grid-row-gap: 0;
}
#logos_partners > div > div {
  padding: 20px;
  align-self: center;
  justify-self: center;
}

@media screen and (max-width: 768px) {
  #team h2 {
    margin-bottom: 30px;
  }
  #team > div > p {
    max-width: 80%;
    margin: auto;
    margin-bottom: 40px;
  }
  #logos_partners {
    padding-top: 70px;
  }
  #logos_partners h3 {
    font-size: 28px !important;
    margin-bottom: 46px !important;
  }
}
