#largeButton .btn {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  text-align: left;
  cursor: pointer;
}

@media (min-width: 992px) {
  #largeButton {
    max-width: 400px;
    display: inline-block;
    margin-right: 20px;
  }
}

.largeBtnDetailed {
  background-color: #1e111e;
  border-radius: 10px;
  display: block;
  border: 1px solid #1e111e;
  min-height: 180px;
  padding: 35px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .largeBtnDetailed {
    min-height: 310px;
  }
}

.largeBtnDetailed h1 {
  margin-top: 15px;
  font-family: var(--font-roboto);
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: -0.25px;
  font-weight: 900;
  color: #ffffff;
}

.largeBtnDetailed p {
  margin-top: 10px;
  color: #ffffff;
  line-height: 1.5;
}

.largeBtnDetailedDark {
  background-color: #333333;
}

.largeBtnDetailedDark h1 {
  color: #ffffff;
}

.largeBtnDetailedDark p {
  color: #ffffff;
}

.readMoreText {
  color: #ffffff;
  max-width: 200px;
  border: 2px solid #ff0000;
  border-radius: 35px;
  text-align: center;
  padding: 15px 25px;
  margin-top: auto;
}
