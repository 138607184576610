#subscribe {
  background: #1e111e;
  padding: 70px 0 !important;
}
#subscribe .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
#subscribe .header p {
  color: #ccc;
  padding-left: 40px;
}
#subscribe h2 {
  font-size: 60px !important;
  line-height: 0.9 !important;
}
#subscribe input {
  background: #322632;
}
#subscribe button {
  text-transform: uppercase;
  background: #9a0772;
}

@media screen and (max-width: 480px) {
  #subscribe {
    padding: 40px 0 !important;
    margin-top: 20px;
  }
  #subscribe h2 {
    font-size: 40px !important;
    line-height: 0.9 !important;
  }
  #subscribe .header {
    display: block;
  }
  #subscribe .header p {
    padding-left: 0;
    margin-top: 20px;
  }
}
