/* Headings */

#monsters_wrapper h1,
#monsters_wrapper h2,
#monsters_wrapper h3 {
  font-weight: normal;
  text-transform: lowercase;
  color: #fff;
  font-size: 100px;
  font-family: 'Carnivalee_Freakshow';
  line-height: 0.82;
}
#monsters_wrapper h1 strong,
#monsters_wrapper h2 strong,
#monsters_wrapper h3 strong {
  font-weight: normal;
}

#monsters_wrapper .red {
  color: #ff0000;
}

#monsters_wrapper p {
  line-height: 1.6;
  color: #ccc;
  margin-bottom: 40px;
}
#monsters_wrapper li {
  line-height: 1.6;
  color: #ccc;
}
#monsters_wrapper p:last-child {
  margin-bottom: 0 !important;
}

/* Basic & Layout */

#monsters_wrapper .inner {
  max-width: 1140px;
  margin: auto;
}
#monsters_wrapper section {
  padding: 120px 0;
}
#monsters_wrapper section.bg {
  background: #1e111e;
}

#monsters_wrapper button {
  cursor: pointer;
  transition: all 0.2s;
  font-family: 'Roboto', sans-serif;
}
#monsters_wrapper .btn {
  background: #9a0772;
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  padding: 20px 30px;
  border-radius: 10px;
  display: inline-block;
  font-weight: 700;
  transition: all 0.2s;
}
#monsters_wrapper .btn:hover,
#monsters_wrapper button:hover {
  background: #b70887;
  transition: all 0.2s;
}
#monsters_wrapper .btn-ghost {
  background: #311c31;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 16px;
  border-radius: 10px;
  display: inline-block;
  font-weight: 700;
  transition: all 0.2s;
  border: 1px solid #472b47;
}
#monsters_wrapper .btn-ghost:hover {
  background: #472b47;
  transition: all 0.2s;
  border: 1px solid #472b47;
}

/* Header */

#monsters_wrapper header {
  z-index: 1000;
}
#monsters_wrapper header.header_scroll {
  background: #000 !important;
  border-bottom: 1px solid #333;
  transition: all 0.2s;
}
#monsters_wrapper nav a.active {
  color: #b70887 !important;
}

/* Forms */

#monsters_wrapper input[type='submit'] {
  width: 100%;
  height: 60px;
  font-weight: 700;
  background: #9a0772 !important;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.2s;
  cursor: pointer;
}
#monsters_wrapper input[type='submit']:hover {
  background: #b70887 !important;
  transition: all 0.2s;
}

#monsters_wrapper .mobile_block {
  display: none;
}

@media screen and (max-width: 1240px) {
  #monsters_wrapper .inner {
    max-width: 88% !important;
  }
}

@media screen and (max-width: 768px) {
  #monsters_wrapper .mobile_block {
    display: block !important;
  }
  #monsters_wrapper .mobile_none {
    display: none !important;
  }

  #monsters_wrapper section {
    padding: 70px 0;
  }
  #monsters_wrapper h2 {
    font-size: 50px;
  }
  #monsters_wrapper p,
  #monsters_wrapper li {
    font-size: 17px;
  }
}
