#basicContentWrapper video {
  max-width: 700px;
}

#basicContentWrapper h1 {
  font-size: 60px;
  color: #ffffff;
  padding-top: 1rem;
  border-bottom: 1px solid #707070;
  padding-bottom: 30px;
}

#basicContentWrapper h5 {
  font-weight: 400;
}
