#customModal {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 101;
  padding: 20px;
  justify-content: flex-start;
  overflow: auto;
}

#customModal p {
  color: #565656;
}

#customModal h5 {
  color: #838383;
}

#modalWrapper {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: black;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  padding: 25px;
}
