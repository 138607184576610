#franchises {
  padding-top: 60px !important;
}
#franchises > div:first-child {
  position: relative;
  top: 50px;
  z-index: 100;
}
#franchises > div:first-child > div > div:first-child {
  justify-self: center;
  z-index: 10;
}
#franchises > div:first-child > div > div:first-child img {
  max-width: 340px;
  z-index: 10;
}
#franchises #txt_earn h2 {
  margin-bottom: 50px;
}
#franchises #txt_earn h2 strong {
  display: block;
  font-size: 66px;
}

#franchises_wrapper #bg {
  width: 100%;
}
#franchises_wrapper > div {
  width: 1360px;
  max-width: 90% !important;
  position: relative;
  margin: auto;
}

#franchises_images {
  position: absolute;
  bottom: 0;
}
#franchises_images img {
  position: relative;
  left: -20px;
}

#franchises_images img.mobile {
  display: none;
}

#franchises_images .data {
  display: none;
}

#franchises_list > div {
  max-width: 100% !important;
}

#franchises_wrapper .data {
  padding-top: 40px;
  position: relative;
  left: 20px;
}
#franchises_wrapper .data p {
  text-transform: uppercase;
  color: #ff0000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 30px;
}
#franchises_wrapper .data span {
  color: #fff;
}
#franchises_wrapper .data h3 {
  font-size: 44px !important;
  margin-bottom: 12px;
}

#franchises_wrapper .data ul li {
  max-width: 80% !important;
  color: #9a0772;
  list-style-type: disc;
  padding-left: 10px;
  font-size: 19px;
  margin-bottom: 24px;
  position: relative;
}
#franchises_wrapper .data ul li:last-child {
  margin-bottom: 0;
}
#franchises_wrapper .data ul li span {
  color: #ccc;
}

@media screen and (max-width: 1440px) {
  #monsters_wrapper #franchises_wrapper > div {
    width: auto !important;
    max-width: 90% !important;
  }
  #monsters_wrapper #franchises_list > div {
    max-width: 100% !important;
  }
}

#franchises p {
  margin-bottom: 30px !important;
}

@media screen and (max-width: 768px) {
  #franchises_wrapper #bg {
    display: none;
  }

  #franchises #txt_earn h2 {
    margin-bottom: 44px;
  }

  #franchises #txt_earn h2 strong {
    font-size: 40px !important;
  }

  #franchises #txt_earn span {
    font-size: 50px !important;
  }

  #franchises > div:first-child > div > div:first-child img {
    max-width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }

  #franchises_images {
    position: relative;
    bottom: 0;
    padding-top: 30px;
  }

  #franchises_images > div > div {
    text-align: center;
    margin-bottom: 40px;
  }

  #franchises_images > div > div:last-child {
    margin-bottom: 0;
  }

  #franchises_images img {
    left: 0;
    max-width: 80%;
    margin: auto;
    display: none;
  }

  #franchises_images img.mobile {
    display: block;
  }

  #franchises_images .data {
    display: block;
    left: 0;
  }

  #franchises_wrapper .data h3 {
    font-size: 36px !important;
    margin-bottom: 12px;
  }

  #franchises_images .data li {
    margin: 0 auto 30px;
    font-size: 17px !important;
  }

  #franchises_list {
    display: none;
  }

  #franchises > div:first-child {
    top: 0;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 540px) {
  #franchises_images img {
    max-width: 100% !important;
  }
}
