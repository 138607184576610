.profileRound {
  border-radius: 50px;
  background-color: #39213a;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.profileInfoWrapper {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
}

.profileInfoWrapper h3 {
  display: inline-block;
  border: 2px solid #aaa;
  font-size: 16px;
  padding: 10px 34px;
  border-radius: 50px;
  color: #fff;
}
@media screen and (max-width: 1100px) {
  .profileInfoWrapper > div {
    margin: auto !important;
    left: 0 !important;
  }
}
