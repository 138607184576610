#viewDetailsContent {
  background-color: transparent;
  overflow: auto;
  transition: max-height 0.6s ease;
  color: #565656;
}

#viewDetails {
  border-radius: 10px;
  padding: 0;
  min-height: 45px;
  width: 100%;
  background: #9a0772;
  border: transparent;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

#viewDetails:hover {
  opacity: 0.9;
}

#viewDetails img {
  margin-right: 10px;
}

.viewDetailsSection {
  width: 100%;
  margin-bottom: 0;
  margin-top: 30px;
}

.view-details-title {
  color: #333333;
}
