.count_wrapper {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.count_wrapper button {
  width: 50px;
  height: 50px;
  background: #9a0772;
  border: none;
  color: #fff;
  font-size: 20px;
}
.count_wrapper button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.count_wrapper span {
  width: calc(100% - 100px);
  font-size: 16px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  height: 50px !important;
  margin: 0 !important;
}
