.accordionContent {
  background-color: transparent;
  overflow: auto;
  transition: max-height 0.6s ease;
  color: #565656;
}

.accordionSection:first-of-type {
  border-top: 1px solid #565656;
}

.accordionSection .accordion {
  background: transparent;
  border-top: 0px solid #565656;
  background-color: #371e37;
  border-bottom: 1px solid #565656;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.accordionSection .accordion::after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid red;
  border-right: 2px solid red;
  top: 50%;
  right: 30px;
  bottom: auto;
  left: auto;
  transform: rotate(135deg);
  margin-top: -7px;
  margin-right: -5px;
  transition: all 0.3s ease-in-out;
}

.accordionSection .accordion[class*='active']::after {
  transform: rotate(-45deg);
  margin-top: -3px;
}

.accordionSection .accordionTitle {
  color: #eeeeee;
  text-transform: uppercase;
}

.accordionContent {
  overflow: hidden;
}

.accordion-button:not(.collapsed) {
  color: unset;
  background-color: unset;
  box-shadow: unset;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='12' height='12' fill='black'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}

.accordion {
  border-bottom: 1px solid #eee;
}

.accordion p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accordion p svg {
  margin-right: 10px;
}

.accordion .accordion-button {
  padding-left: 0;
  padding-right: 0;
}
