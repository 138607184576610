/* Hero */

#hero {
  height: 100vh;
  background: url(../../../assets/monsters/images/bg_hero.jpg) no-repeat center;
  background-size: cover;
  display: grid;
  align-content: center;
  justify-content: center;
}
#hero .data {
  text-align: center;
}
#hero .data .logo {
  position: relative;
  left: 20px;
  max-width: 80%;
  margin: auto;
}

#social_mobile {
  text-align: center;
  margin-top: 80px;
}
#social_mobile img {
  left: 0;
  height: 26px;
}
#social_mobile a {
  display: inline-block;
  margin: 0 10px;
}

#hero .logo_mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .hero_container p {
    height: 600px;
    color: rgb(72, 63, 160);
  }

  #hero .logo_mobile {
    display: block;
    margin-bottom: 30px;
  }
  #hero .logo {
    display: none;
  }
  #social_mobile img {
    height: 20px;
  }
}

.separator {
  display: block;
  margin: 15px auto;
  max-width: 30px;
  text-align: center;
  font-family: var(--font-carnivalee);
  position: relative;
}

.separator::before,
.separator::after {
  content: '';
  height: 1px;
  width: 30px;
  background-color: #ccc;
  position: absolute;
  top: 50%;
  bottom: auto;
}

.separator::before {
  right: 100%;
  left: auto;
  margin-right: 15px;
}

.separator::after {
  left: 100%;
  right: auto;
  margin-left: 15px;
}
