/* Mint */

#mint_module {
  max-width: 432px;
  background: #1e111e;
  border-radius: 10px;
  padding: 30px;
  display: none;
} /* MODULO DE MINTEO, DSP REMOVER NONE*/
#mint_module h2 {
  font-size: 46px;
}
#mint_module input {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}
#mint_module > div {
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#mint_module > div:first-child {
  padding-top: 0;
}
#mint_module > div:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
#mint_module .price img {
  position: relative;
  top: -14px;
}
#mint_module .price > div {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 50% 50%;
  text-align: right;
  position: relative;
  top: 20px;
  margin-bottom: 30px;
}
#mint_module .price > div > *:last-child {
  justify-self: end;
}
#mint_module .price > div span {
  font-size: 13px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 6px;
}
#mint_module .price > div strong {
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 400;
}
#mint_module .total {
  margin-bottom: 30px;
}
#mint_module .total p span {
  float: right;
}

h1 i {
  font-family: 'Roboto', sans-serif;
  margin-right: 4px;
}

#subscribe_mint,
#whitelist_form {
  background: #1e111e;
  padding: 40px;
  position: relative;
  border-radius: 10px;
}
#subscribe_mint p,
#whitelist_form p {
  color: #ccc;
  font-size: 18px;
  margin-bottom: 20px !important;
}
#subscribe_mint h2,
#whitelist_form h2 {
  font-size: 48px !important;
  line-height: 0.9 !important;
  margin-bottom: 20px;
}
#subscribe_mint button {
  text-transform: uppercase;
  background: #9a0772;
}

#whitelist_form input {
  background: #322632;
  width: 100%;
}
#whitelist_form .submit {
  margin-top: 16px;
}
#whitelist_form #wallet_id {
  font-size: 16px;
  margin-top: 16px;
  border: 1px solid #322632;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#whitelist_form #wallet_id span {
  font-weight: 700;
  margin-right: 10px;
}
#whitelist_form h2 {
  margin-bottom: 24px !important;
}
#whitelist_form p {
  margin-bottom: 34px !important;
  font-size: 17px;
}

.validation_area {
  padding: 20px 0 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}
#whitelist_form .validation_area p {
  margin-bottom: 0 !important;
}
#whitelist_form .validation_area img {
  width: 20px;
}

#subscribe_mint > div > div {
  display: block !important;
}
#subscribe_mint > div > div > fieldset {
  display: block !important;
  margin: 0 !important;
}
#subscribe_mint input {
  width: 100%;
  margin: 0 !important;
  margin-top: 16px !important;
  background: #322632;
}
#subscribe_mint button {
  margin-top: 16px;
}

.btn_disconnect {
  background-color: black;
  border-radius: 60px;
  border: 0;
  color: #ffffff;
  padding: 10px 10px;
}

.btn_disconnect:hover {
  background-color: gray;
  cursor: pointer;
}
.btn_disconnect img {
  height: 10px;
  margin: 0 auto !important;
  display: block;
}

@media screen and (max-width: 768px) {
  #subscribe_mint {
    top: 0;
    padding: 40px 30px;
    margin-bottom: 60px;
  }
  #subscribe_mint h2 {
    font-size: 40px;
  }

  #mint_area > div > div > img {
    display: block;
  }
  #mint_area > div > div:last-child {
    margin-bottom: 0;
  }
  #whitelist_form {
    padding: 40px 24px;
  }
  #whitelist_form h2 {
    font-size: 36px;
  }
  #whitelist_form #wallet_id {
    padding: 20px 10px;
    font-size: 13px;
  }

  .btn_disconnect {
    background-color: black;
    border-radius: 60px;
    border: 0;
    color: #ffffff;
    padding: 8px 8px;
    width: 20px;
    height: 20px;
  }
  .btn_disconnect img {
    height: 5px;
    display: block !important;
  }
}
