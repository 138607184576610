#tabsWrapper {
  margin: auto;
}

#navTabs {
  border-bottom: 0px solid transparent;
  display: flex;
  justify-content: center;
  align-content: flex-end;
}

#navTabs .navItem a {
  max-width: 150px;
  text-align: center;
}

#navLink.active::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
}

#navLink {
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 993px) {
  #navLink {
    flex-direction: column;
  }
}

.tabIcon {
  max-width: 25px;
  height: 100%;
  margin-right: 10px;
}

.tabTitle {
  margin-top: 0;
}

#navTabs li {
  background-color: #1e111e;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 550px;
}

#navTabs li a {
  padding-top: 30px;
  padding-bottom: 30px;
}

#navTabs li[class*='active'] {
  background-color: #371e37;
}
