.infoLink {
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #cccccc;
  font-size: 14px;
  padding-top: 30px;
  border-top: 1px solid #565656;
  margin-top: auto;
}

h1.mainTitle {
  padding-bottom: 30px;
  border-bottom: 1px solid #565656;
}

button {
  align-self: self-start;
}
