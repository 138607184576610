#transmedia {
}
#transmedia h2 {
  margin-bottom: 60px;
}

#transmedia h2 span {
  display: block;
  font-size: 66px;
}

#transmedia ul {
  position: relative;
  left: 30px;
  padding-right: 40px !important;
  max-width: 80%;
}
#transmedia li {
  margin-bottom: 20px !important;
  color: #9a0772 !important;
  list-style-type: disc;
  padding-left: 10px;
}
#transmedia li span {
  color: #ccc !important;
}
#transmedia #transmedia_img {
  align-self: end;
}

@media screen and (max-width: 768px) {
  #transmedia #transmedia_img {
    max-width: 80%;
    margin: auto;
  }
  #transmedia ul {
    margin-bottom: 40px !important;
    max-width: 94%;
  }

  #transmedia h2 {
    margin-bottom: 44px;
  }

  #transmedia h2 span {
    display: block;
    font-size: 40px;
    margin-bottom: 6px;
  }
}
