#about {
  padding-bottom: 0 !important;
}
#about h2 {
  margin-bottom: 44px;
  line-height: 0.7;
}
#about h2 span {
  font-size: 63px;
  display: block;
  position: relative;
  top: -10px;
}
#about #showcase {
  align-self: end;
  /* justify-self: end; */
  border: 14px solid #39213a;
  border-radius: 5px;
}

#about > div > div {
  display: grid;
  grid-template-columns: 50% 40%;
  grid-column-gap: 40px;
  justify-content: space-between;
}

#about > div > div > div:first-child {
  margin-bottom: 110px;
}
#about > div > div > div:last-child {
  align-self: end;
}

@media screen and (max-width: 768px) {
  #about h2 span {
    font-size: 40px;
    top: -4px;
  }
  #about #showcase {
    margin-top: 30px;
  }
  #about #showcase {
    justify-self: center;
    margin-top: 50px;
  }

  #about > div > div {
    display: block;
  }

  #about > div > div > div:first-child {
    margin-bottom: 50px;
  }

  #about > div > div > div:last-child img {
    max-width: 80%;
    margin: auto;
  }
}
