.cardWrapper {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #1e111e;
  margin-top: 25px;
  border-radius: 10px;
  padding: 25px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .cardWrapper {
    max-width: 450px;
    margin-right: 20px;
  }
}

.cardWrapper .detailTitle {
  font-size: 24px;
  margin: 20px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cardWrapper .detailTitle img {
  max-width: 40px;
}
.cardWrapper #label {
  position: absolute;
  right: 0;
  background-color: rgba(255, 255, 255, 0.15);
  top: 20px;
  padding: 10px 20px;
  border-radius: 10px 0px 0px 10px;
  color: #ffffff;
  font-size: 15px;
}

.cardWrapper p {
  line-height: 1.3;
}
