#video {
  text-align: center;
  padding: 110px 0;
}
#video h2 {
  margin-bottom: 60px;
}
#video #video_wrapper {
  margin-bottom: 60px !important;
}

#video #video_wrapper video {
  width: 100%;
  margin: auto;
}
#alan_bio {
  max-width: 90%;
  margin: auto;
}
#alan_bio a {
  color: #ff0000;
}
#alan_bio p {
  font-size: 18px;
}
#alan_bio p.last {
  margin-bottom: 0 !important;
}
#bio_extra {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s;
}
#bio_extra.active {
  max-height: 1000px;
  padding-bottom: 60px;
  transition: all 0.4s;
}
#alan_images {
  max-width: 80%;
  margin: 60px auto;
}
#alan_bio button {
  border: 0;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  #video {
    text-align: left;
    padding: 60px 0;
  }
  #video h2 {
    margin-bottom: 40px;
  }
  #video h2 strong {
    display: block;
  }
  #video #video_wrapper {
    margin-bottom: 30px;
  }

  #alan_images,
  #alan_bio {
    max-width: 100%;
  }
  #alan_images {
    margin: 40px 0;
  }

  #bio_extra.active {
    max-height: 2000px;
  }
  #alan_bio p.last {
    margin-bottom: 40px !important;
  }
}
