aside.bn-onboard-modal {
  background: rgba(0, 0, 0, 0.5) !important;
}
aside.bn-onboard-modal > section {
  padding: 30px !important;
}
.bn-onboard-custom .bn-branding {
  display: none !important;
}
.bn-onboard-custom.bn-onboard-modal-select-wallets {
  margin-top: 30px !important;
  justify-content: space-between;
}
.bn-onboard-custom > li {
  flex: 1;
  padding-right: 10px !important;
}
@media screen and (min-width: 992px) {
  .bn-onboard-custom > li {
    min-width: 150px;
  }
}
.bn-onboard-custom > li > button {
  width: 100%;
  display: block;
}
.bn-onboard-custom > li + li {
  padding-right: 0 !important;
  padding-left: 10px !important;
}
.bn-onboard-custom p {
  line-height: 1.3;
  max-width: 90%;
}
.bn-onboard-dark-mode p,
.bn-onboard-modal-content.bn-onboard-dark-mode p.bn-onboard-select-description {
  color: #999 !important;
}

.bn-onboard-custom .bn-onboard-icon-button {
  height: 140px;
  margin: 0 !important;
  margin-bottom: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bn-onboard-custom .bn-onboard-icon-button span {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bn-onboard-modal-select-wallets {
  justify-content: space-between;
}
.bn-onboard-modal-select-wallets .bn-onboard-icon-button {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  margin-bottom: 0 !important;
}
.bn-onboard-modal-select-wallets .bn-onboard-icon-button.bn-onboard-dark-mode-background-hover {
  border: 1px solid #67686a;
}

.bn-onboard-modal-content-header-icon {
  background: none !important;
  padding-left: 0 !important;
}

.bn-onboard-modal-content-header {
  margin-bottom: 0 !important;
}
.bn-onboard-modal-content-header h3 {
  font-size: 20px !important;
  margin: 0 !important; /* font-family: "Neuzeit_Bold" !important; */
}
.bn-onboard-modal-content .bn-onboard-select-description {
  margin: 0;
  margin-top: 10px !important;
  color: #363240 !important;
  font-size: 16px !important;
}
.bn-onboard-modal-content.bn-onboard-dark-mode .bn-onboard-select-description {
  color: #d4d4d4 !important;
}

.bn-onboard-modal-select-wallets li + li + li .bn-onboard-icon-button {
  margin: 0 !important;
}
.bn-onboard-custom .bn-onboard-icon-button:hover {
  background: #e9ebee;
  box-shadow: 0;
}

.bn-onboard-custom.bn-onboard-dark-mode {
  background: #555 !important;
}

.bn-onboard-custom .bn-onboard-icon-button.bn-onboard-dark-mode-background-hover:hover,
.bn-onboard-custom .bn-onboard-icon-button.bn-onboard-dark-mode-background {
  background: #444 !important;
}

.bn-onboard-custom .bn-onboard-icon-button.bn-onboard-selected-wallet {
  background: #e9ebee !important;
}
.bn-onboard-custom.bn-onboard-dark-mode .bn-onboard-icon-button.bn-onboard-selected-wallet {
  background: #444 !important;
}

.bn-onboard-custom .bn-onboard-modal-content-close {
  width: 16px !important;
  height: 16px !important;
  background: #f5f5f5 !important;
  border-radius: 50% !important;
}
.bn-onboard-custom .bn-onboard-modal-content-close svg {
  width: 6px !important;
  height: 6px !important;
}
.bn-onboard-custom.bn-onboard-dark-mode .bn-onboard-modal-content-close {
  background: #252525 !important;
}

.bn-onboard-custom .bn-onboard-icon-button span {
  /* font-family: "Neuzeit_Bold"; */
  display: block;
  text-align: center;
  margin-left: 0 !important;
}
.bn-onboard-custom .bn-onboard-icon-button span i {
  display: block;
  margin-top: 8px;
}

/* Login and Authorize your wallet */

.bn-onboard-prepare-description {
  margin-bottom: 30px !important;
}
.bn-onboard-prepare-error {
  padding: 14px 10px !important;
  margin-bottom: 28px;
}

.bn-onboard-custom.bn-onboard-select-wallet-info {
  color: white !important;
  border-bottom: 1px solid white;
  font-size: 15px !important;
}

.bn-onboard-custom.bn-onboard-select-wallet-definition {
  line-height: 1.4;
}

.bn-onboard-custom.bn-onboard-select-wallet-definition a {
  color: red !important;
  border-bottom: 1px solid red;
}

/* Uninstalled Wallet  */

.bn-onboard-modal-selected-wallet p {
  margin-bottom: 30px !important;
}
.bn-onboard-modal-selected-wallet .bn-onboard-icon-display {
  margin: 30px 0;
}
.bn-onboard-modal-selected-wallet .bn-onboard-icon-display img {
  height: 30px;
}

/* Selected Wallet */

.bn-onboard-select-info-container {
  margin-top: 15px;
}

/* Monster Styles */

.bn-onboard-custom.bn-onboard-dark-mode {
  background: #000 !important;
  border: 1px solid #444;
}
.bn-onboard-modal-select-wallets .bn-onboard-icon-button {
  border: 4px solid #333 !important;
  background: #111 !important;
  transition: all 0.2s;
}
.bn-onboard-custom .bn-onboard-icon-button.bn-onboard-dark-mode-background-hover:hover,
.bn-onboard-custom .bn-onboard-icon-button.bn-onboard-dark-mode-background {
  background: #222 !important;
  transition: all 0.2s;
}
.bn-onboard-prepare-button {
  border: 2px solid #333 !important;
  border-radius: 50px;
  color: #fff !important;
  background: #111 !important;
}
.bn-onboard-prepare-button:hover {
  background: #222 !important;
}

@media screen and (max-width: 768px) {
  aside.bn-onboard-modal {
    padding: 20px !important;
  }
}
