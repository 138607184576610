#chomp {
  padding-bottom: 0 !important;
}
#chomp > div > div {
  justify-self: center;
}
#chomp > div > div:first-child,
#chomp > div > div:last-child {
  align-self: end;
}
#chomp h2 {
  text-align: center;
  font-size: 58px !important;
  margin-bottom: 40px;
}
#chomp h2 span {
  font-size: 145px;
  display: block;
}
#chomp #txt_chomp {
  text-align: center;
}

@media screen and (max-width: 768px) {
  #chomp {
    padding-top: 40px !important;
  }
  #chomp h2 {
    font-size: 38px !important;
  }
  #chomp h2 span {
    font-size: 96px;
  }
  #chomp img {
    margin-top: 40px;
  }
}
