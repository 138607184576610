.custom-modal {
  padding: 0px;
}

.alert {
  border: 1px solid #918787;
  border-radius: 8px;
}

.alert img {
  max-width: 20px;
}

.alert p {
  color: #838383;
  transform: translateX(20px);
  max-width: 200px;
}
