.stepImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.stepImage {
  height: 290px;
  max-width: 290px;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (max-width: 993px) {
  .stepImage {
    margin-left: 0px;
  }
}

.stepperNumber {
  width: 40px;
  height: 40px;
  background-color: #9a0772;
  border-radius: 20px;
  color: #ffffff;
  line-height: 40px;
}

.stepperLine {
  position: relative;
  z-index: 10;
}
.stepperLine::after {
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background-color: #c2c2c2;
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translateX(-1px);
}

.modal-wrapper {
  margin-bottom: 0px !important;
}

.modal-wrapper img {
  max-width: 50%;
}

.modal-wrapper span {
  color: #838383;
}

.modal-wrapper span:before {
  content: '';
  display: inline-block;
  height: 15px;
  width: 1px;
  background-color: #838383;
  margin-right: 5px;
}

.modal-wrapper a {
  color: #333333;
}

.stepItem .accordionWrapper h1 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 22px;
  padding-bottom: 15px;
}

.stepItem .accordionWrapper h5 {
  color: #cccccc;
  font-style: italic;
  font-size: 15px;
  font-weight: 400;
  margin-top: 20px;
}

.stepItem .accordionWrapper p {
  color: #cccccc;
  line-height: 1.5;
}
