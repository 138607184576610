/* Mint */

#mint {
  padding: 10px 0 0 !important;
}
#mint h1 {
  margin-bottom: 0;
  text-align: center;
  position: relative;
  top: -30px !important;
}
#mint h1 span {
  font-size: 48px;
  display: block;
  margin-top: 16px;
}
#mint h1 span small {
  font-size: 46px;
}

#mint_area {
  padding-top: 116px;
}
#mint_area > div > div:last-child {
  align-self: center;
  margin-bottom: 80px;
}
#mint_area > div > div > img {
  margin: auto;
}

#mint_area > div > div:first-child {
  align-self: end;
}

#mint_area h1 {
  position: relative;
  top: 30px;
}

@media screen and (max-width: 768px) {
  #mint {
    padding-bottom: 60px !important;
  }

  #mint_area {
    padding-top: 60px;
    text-align: center;
  }
  #mint_area img {
    display: inline-block;
    margin-bottom: -2px !important;
  }
  #mint_area > div {
    grid-row-gap: 0;
  }

  #mint_area > div > div:last-child {
    align-self: center;
    margin-bottom: 0;
  }

  #mint h1 {
    font-size: 54px !important;
    margin-bottom: 34px;
    top: 0 !important;
  }
  #mint h1 span {
    font-size: 33px !important;
    display: block;
  }
  #mint h1 span small {
    font-size: 25px !important;
  }
}
