/* Mint */

#mint_module {
  max-width: 432px;
  background: #1e111e;
  border-radius: 10px;
  padding: 30px;
} /* MODULO DE MINTEO, DSP REMOVER NONE*/
#mint_module h2 {
  font-size: 50px !important;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}
#mint_module h3 {
  font-size: 30px !important;
  margin-bottom: 15px;
}
#mint_module input {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}
#mint_module > div {
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#mint_module > div:first-child {
  padding-top: 0;
}
#mint_module > div:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
#mint_module .price img {
  position: relative;
  top: -14px;
}
#mint_module .price > div {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 50% 50%;
  text-align: right;
  position: relative;
  top: 20px;
  margin-bottom: 30px;
}
#mint_module .price > div > *:last-child {
  justify-self: end;
}
#mint_module .price > div span {
  font-size: 13px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 6px;
}
#mint_module .price > div strong {
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 400;
}
#mint_module .total {
  margin-bottom: 20px;
}
#mint_module .total p span {
  float: right;
}

h1 i {
  font-family: 'Roboto', sans-serif;
  margin-right: 4px;
}

#wallet_id {
  font-size: 16px;
  margin-top: 16px;
  border: 1px solid #322632;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#mint_module .btn_main {
  width: 100%;
  height: 60px;
  font-weight: 700;
  background: #9a0772 !important;
  border-radius: 5px;
  text-transform: uppercase;
  margin-top: 16px;
  line-height: 34px;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  font-size: 16px;
  color: #fff;
}

#mint_module .btn_main:hover {
  opacity: 0.8;
}

#mint_module .minting_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-top: solid 1px #707070;
  border-bottom: solid 1px #707070;
  padding: 15px 20px;
}

#mint_module .minting_process > p:first-child {
  margin-bottom: 25px;
}
#mint_module .minting_process li {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 0;
}

#mint_module .minting_process li:last-child {
  border-bottom: none;
  padding: 15px 0 0;
}

#mint_module .view_etherscan {
  color: #ccc;
  font-size: 12px;
  font-style: italic;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 3px;
  margin-bottom: 9px;
}

#mint_module .sub_title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#mint_module li .btn_main {
  margin-top: 0;
}

#mint_module .not_in_whitelist_text {
  font-size: 14px;
}

#mint_module .mint_close_text {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  #subscribe_mint {
    top: 0;
    padding: 40px 30px;
    margin-bottom: 60px;
  }
  #subscribe_mint h2 {
    font-size: 40px;
  }

  #mint_area > div > div > img {
    display: block;
  }
  #mint_area > div > div:last-child {
    margin-bottom: 0;
  }

  #wallet_id {
    padding: 20px 10px;
    font-size: 13px;
  }

  #mint_module .price > div strong {
    font-size: 26px;
  }

  .total,
  .minting_total {
    text-align: left;
  }
  #mint_module {
    margin: auto;
  }
  #mint_module h2 {
    font-size: 30px !important;
  }
}
