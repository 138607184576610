#footer {
  padding: 86px 0;
}
#footer {
  background: #131113;
  text-align: center;
}
#footer nav {
  margin-bottom: 50px;
}
#footer nav li {
  margin: 0 12px;
  display: inline-block;
}
#footer nav li a {
  color: #fff !important;
  font-size: 18px;
}
#footer a:hover {
  color: #ccc;
  transition: all 0.2s;
}
#social_footer {
  margin-bottom: 50px;
}
#social_footer a {
  display: inline-block;
  margin: 0 14px;
  transition: all 0.2s;
  opacity: 1;
}
#social_footer a:hover {
  opacity: 0.8;
  transition: all 0.2s;
}
#social_footer a img {
  height: 20px;
}
#copyright {
  color: #444;
  font-size: 16px;
}

#sponsors > div > div > * {
  display: inline-block;
}
#sponsors #nft_calendar_logo {
  width: 100px;
  position: relative;
  top: 28px;
}

@media screen and (max-width: 768px) {
  #footer {
    padding: 50px 0;
  }
  #copyright {
    font-size: 14px !important;
  }
  #copyright span {
    display: block;
  }
  #social_footer img {
    max-height: 20px;
  }
  #sponsors #nft_calendar_logo {
    width: 90px;
    top: 22px;
  }

  #footer nav li a {
    font-size: 17px;
    margin-top: 16px;
    display: inline-block;
  }
}
