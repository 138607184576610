.walletFaq {
  border-bottom: 2px solid #333333;
  position: relative;
}

.walletFaq:hover {
  background-color: #1e111e;
}

.walletFaq::before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  z-index: 100;
  position: absolute;
  top: 60%;
  transform: rotate(225deg) translate(20px, 0px);
  right: 0;
  border-bottom: 1px solid #ff0000;
  border-left: 1px solid #ff0000;
}

.walletFaq a {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  padding: 25px;
  display: block;
}

.walletFaq a h3 {
  max-width: 315px;
  margin-bottom: 0;
}

.faqContainer h1 {
  color: #ffffff;
  padding: 30px;
  padding-left: 0;
  border-bottom: 1px solid #565656;
}

.faqContainer p {
  color: #cccccc;
  font-weight: 400;
  line-height: 1.5;
}
