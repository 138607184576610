.questionnaire {
  border-top: 1px solid #918787;
  border-bottom: 1px solid #918787;
}

.questionnaire h1 {
  color: #838383;
}

.answer-questionnaire {
  display: flex;
  justify-content: space-evenly;
}

.answer-questionnaire button {
  color: #333333;
  max-width: 45% !important;
}
