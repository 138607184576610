.pillInfo {
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  min-width: 110px;
  max-width: 110px;
  display: block;
  min-height: 100px;
  margin-top: 20px;
}

.pillInfo h1,
.pillInfo h2,
.pillInfo p {
  color: #ffffff;
  margin-top: 5px;
  font-size: 14px;
}

.pillsWrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
}
