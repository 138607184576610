/* Header */

#header {
  height: 100px;
  padding: 10px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  transition: all 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #000 !important;
  border-bottom: 1px solid #333;
}

#header nav a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
}
#header nav a:hover {
  color: #ccc;
  transition: all 0.2s;
}
#header nav li {
  display: inline-block;
  margin-right: 36px;
}

#header img {
  width: 60px;
  margin-right: 40px;
}
#header #isodesktop:hover {
  transform: rotate(-10deg);
}

@media screen and (max-width: 900px) {
  #header nav li {
    margin-right: 26px;
  }
  #header nav a {
    font-size: 17px;
  }
}
