.marquee {
  overflow: hidden;
}
.marquee_content {
  display: flex;
  animation: scrolling 60s linear infinite;
}
.marquee_item {
  flex: 0 0 16vw;
  margin: 0 7px;
}
.marquee_item img {
  display: block;
  width: 100%;
  padding: 0;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}

@media screen and (max-width: 768px) {
  .marquee_content {
    animation: scrolling 30s linear infinite;
  }
  .marquee_item {
    flex: 0 0 32vw;
    margin: 0 4px;
  }
}
