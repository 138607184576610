:root {
  --font-roboto: 'Roboto', sans-serif;
  --font-carnivalee: 'Carnivalee_Freakshow', serif;
}

/* Reset */

a {
  text-decoration: none !important;
  cursor: pointer;
}
ul {
  padding: 0 !important;
  margin: 0 !important;
}

/* Fonts */

@font-face {
  font-family: 'Carnivalee_Freakshow';
  src: url('assets/monsters/fonts/CarnivaleeFreakshow.eot');
  src: url('assets/monsters/fonts/CarnivaleeFreakshow.eot?#iefix') format('embedded-opentype'),
    url('assets/monsters/fonts/CarnivaleeFreakshow.woff2') format('woff2'),
    url('assets/monsters/fonts/CarnivaleeFreakshow.woff') format('woff'),
    url('assets/monsters/fonts/CarnivaleeFreakshow.ttf') format('truetype'),
    url('assets/monsters/fonts/CarnivaleeFreakshow.svg#CarnivaleeFreakshow') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  background: #000 !important;
  font-family: var(--font-roboto);
  font-size: 20px;
  color: #ccc;
}

.slick-next,
.slick-prev {
  z-index: 100;
  width: 20px !important;
  height: 26px !important;
  background-size: 100%;
  top: 35%;
}

.slick-next,
.slick-next:hover {
  right: -60px !important;
  background: url('./assets/monsters/images/arrow_left.svg') no-repeat center !important;
}
.slick-prev,
.slick-prev:hover {
  left: -60px !important;
  background: url('./assets/monsters/images/arrow_right.svg') no-repeat center !important;
}
.slick-next::before,
.slick-prev::before {
  display: none;
}

.slick-dots {
  bottom: -80px;
}
.slick-dots li {
  width: 10px !important;
}
.slick-dots li button:hover {
  background: none !important;
}
.slick-dots li button::before {
  color: #fff !important;
  font-size: 9px !important;
}

@media screen and (max-width: 1300px) {
  .slick-next,
  .slick-next:hover {
    right: -40px !important;
  }
  .slick-prev,
  .slick-prev:hover {
    left: -40px !important;
  }
}

@media screen and (max-width: 768px) {
  .slick-dots {
    bottom: -20px;
    position: relative;
  }
}

/* Universal styles used on onboard */
.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}
.pt-5 {
  padding-top: 5rem !important;
}

.px-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-4 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2em !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.background-transparent {
  background-color: transparent;
}

.border-none {
  border: 0px solid transparent;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pl-1 {
  padding-left: 0.5rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pl-2 {
  padding-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.border-transparent {
  border: 1px solid transparent;
}

.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.form-check-input {
  border: 1px solid #000;
}

.form-check-input:checked {
  background-color: #000;
  border-color: #000;
}

.btn-link {
  text-decoration: none;
  color: #000;
}

.btn-link :hover {
  color: #000;
}

.btn-link:focus {
  box-shadow: 0 0 0 0.25rem rgb(41 43 45 / 25%);
}

.text-sm {
  font-size: 0.8rem;
}

.text-muted-x {
  color: #c4c4c4;
}

.price-editions .small {
  font-size: 0.75em;
}

.text-justify {
  text-align: justify;
}

.fs-7 {
  font-size: 10px;
}

.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-40 {
  font-size: 40px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-60 {
  font-size: 60px;
}

.fw-bold {
  font-weight: 700px;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-35 {
  width: 35%;
}

@media (min-width: 992px) {
  .w-md-35 {
    width: 35%;
  }
}

.w-65 {
  width: 65%;
}

@media (min-width: 992px) {
  .w-md-65 {
    width: 65%;
  }
}

.w-80 {
  width: 80%;
}

@media (min-width: 992px) {
  .w-md-80 {
    width: 80%;
  }
}

.w-85 {
  width: 85%;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-dark {
  color: #333333;
}

.text-muted {
  color: #838383 !important;
}

.text-capitalize-first:first-letter {
  text-transform: capitalize;
}

.text-muted-placeholder {
  color: #c4c4c4 !important;
}

input.clean-input {
  border: unset;
  background-color: unset;
}

input.clean-input:focus-visible {
  outline: unset;
}

.card {
  border-radius: 8px !important;
}

.radius-8 {
  border-radius: 8px !important;
}

.offcanvas {
  width: 90%;
}

.offcanvas-title {
  width: 100%;
}

.h-70 {
  height: 70vh;
}

.h-80 {
  height: 80vh;
}

.bg-gray-f9 {
  background-color: #f9f9f9;
}

.bg-violet {
  background-color: #1e111e;
}

.muted-icon {
  color: #838383;
}

.uppercase {
  text-transform: uppercase;
}

.background-gray {
  background-color: #f5f5f5;
}

.background-dark-gray {
  background-color: #333333;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-block {
  display: none;
}

.d-none {
  display: none;
}

@media (min-width: 992px) {
  .d-md-flex {
    display: flex !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-none {
    display: none !important;
  }
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.medium-wrapper {
  max-width: 600px;
}

@media (min-width: 768px) {
  .container-fluid {
    padding-right: var(--bs-gutter-x, 5rem);
    padding-left: var(--bs-gutter-x, 5rem);
  }
}

.color-red {
  color: #ff0000;
}

.font-carnival {
  font-family: var(--font-carnivalee) !important;
}
