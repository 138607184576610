#sign {
  padding: 60px 0 0;
}
#sign p {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #ccc;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#sign img {
  width: 110px;
  display: inline-block;
  margin: 0 20px;
}

@media screen and (max-width: 768px) {
  #sign p {
    font-size: 10px;
  }
}
