#press {
  padding-top: 0 !important;
}
#press > div {
  padding-top: 80px;
}
#press .img_wrapper {
  margin-bottom: 40px;
  display: block;
}
#press h2 {
  text-align: center;
  margin-bottom: 34px;
}
#press .txt {
  text-align: center;
  font-size: 20px !important;
  margin-bottom: 76px !important;
}
#press h3 {
  font-size: 36px !important;
  position: relative;
  padding-bottom: 30px;
  padding-right: 88px;
  margin-bottom: 30px;
  border-bottom: 1px solid #707070;
}
#press h3 img {
  position: absolute;
  right: 0;
  top: 4px;
  max-height: 22px;
}
#press .btn_press {
  border: 3px solid #ff0000;
  color: #fff;
  font-size: 15px;
  display: inline-block;
  border-radius: 50px;
  padding: 12px 40px;
  text-align: center;
  transition: all 0.2s;
}
#press .btn_press:hover {
  background: #111;
  transition: all 0.2s;
}
#press article p {
  font-size: 17px;
  min-height: 85px;
}

#press_carousel {
  margin: 0 -20px;
}
#press_carousel > div > div > div > div {
  padding: 0 20px;
}
#press_carousel button:first-child {
  left: -40px !important;
}
#press_carousel button:last-child {
  right: -40px !important;
}

@media screen and (max-width: 1320px) {
  #press_carousel button:first-child {
    left: -20px !important;
  }
  #press_carousel button:last-child {
    right: -20px !important;
  }
}

@media screen and (max-width: 768px) {
  #press > div {
    padding-top: 60px;
  }
  #press_carousel > div > div > div > div {
    padding: 0;
  }
  #press .txt {
    margin-bottom: 50px !important;
    font-size: 17px !important;
  }
  #press article p {
    min-height: 0;
    margin-bottom: 30px !important;
  }
  #press_carousel {
    margin: 0;
  }
  #press h3 {
    padding-right: 0;
  }
  #press h3 img {
    display: none;
  }
}
