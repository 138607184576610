#menu {
  text-align: center;
}
#menu h2 {
  margin-bottom: 40px !important;
}
#menu .txt {
  margin-bottom: 70px !important;
}

#menu_slider .img_wrapper {
  border-radius: 20px;
  background: #1e111e;
  padding: 70px 40px;
  text-align: center;
  margin-bottom: 40px;
}
#menu_slider .img_wrapper img {
  display: inline-block;
  height: 200px;
}
#menu_slider h3 {
  font-size: 36px !important;
  margin-bottom: 20px;
}
#menu_slider p {
  max-width: 90%;
  font-size: 16px;
  margin: auto;
}
#menu_slider > div {
  grid-column-gap: 0;
}
#menu_slider .slide_menu > div {
  position: relative;
}
#menu_slider .slide_menu > div:first-child {
  margin-right: 50px;
}
#menu_slider .slide_menu > div:last-child {
  margin-left: 50px;
}
#menu_slider .slide_menu > div:first-child:after {
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  right: -50px;
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
}

#menu_slider .slide_menu {
  display: grid !important;
  grid-template-columns: repeat(12, 1fr);
}

@media screen and (max-width: 1300px) {
  #menu > div:first-child {
    max-width: 1000px;
  }
}

@media screen and (max-width: 768px) {
  #menu {
    padding-bottom: 80px !important;
  }
  #menu h2 {
    margin-bottom: 30px !important;
  }
  #menu .txt {
    margin-bottom: 40px !important;
  }
  #menu_slider > div > div {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  #menu_slider > div > div::after {
    display: none;
  }
  #menu_slider p {
    max-width: 100%;
  }
  #menu_slider h3 {
    font-size: 30px;
  }
  #menu_slider .slide_menu > div:last-child {
    display: none;
  }
  #menu_slider .slide_menu > div:first-child,
  #menu_slider .slide_menu > div:last-child {
    margin: 0;
  }
  #menu_slider .slide_menu > div:first-child:after {
    display: none;
  }

  #menu_slider .img_wrapper {
    border-radius: 0;
  }
}
