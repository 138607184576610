/* Subscribe */

#subscribe_form_container .suscribe_form {
  display: grid;
  grid-template-columns: auto 200px;
  grid-column-gap: 20px;
}
#subscribe_form_container .suscribe_form > fieldset:first-child {
  display: flex;
  margin: 0 -10px;
}
#subscribe_form_container .suscribe_form > fieldset:first-child input {
  flex: 1;
  margin: 0 10px;
}
#subscribe_form_container h2 {
  font-size: 65px;
  color: #fff;
}
input,
.suscription_submit {
  height: 54px;
  line-height: 34px;
  border: 0;
  background: #242424;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 12px;
  font-size: 16px;
  color: #fff;
}
.suscription_submit {
  width: 100%;
  background: #3e0c80;
  padding: 0 !important;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  #subscribe_form_container .suscribe_form {
    display: block;
  }
  #subscribe_form_container .suscribe_form > fieldset:first-child {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  #subscribe_form_container .suscribe_form > fieldset:first-child {
    display: block;
    margin: 0;
  }
  #subscribe_form_container .suscribe_form > fieldset:first-child input {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }
}
