#roadmap {
  padding-bottom: 0 !important;
}
#roadmap h2 {
  text-align: center;
  margin-bottom: 20px;
}
#roadmap h3 {
  text-align: center;
  font-size: 46px !important;
  margin-bottom: 60px;
}

#roadmap #milestones {
  grid-column-gap: 60px;
  position: relative;
}
#roadmap #milestones > div {
  text-align: center;
  position: relative;
  z-index: 1;
}
#roadmap .img_wrapper {
  background: #130b13;
  margin-bottom: 40px;
  padding: 40px;
  min-height: 280px;
  text-align: center;
  border-radius: 5px;
  display: grid;
  align-content: center;
}
#roadmap .img_wrapper img {
  display: inline-block;
  max-height: 200px;
  margin: auto;
}
#roadmap #milestones strong {
  color: #ff0000;
  font-size: 72px;
  margin-bottom: 22px;
  font-family: 'Carnivalee_Freakshow';
  font-weight: normal;
  display: block;
}
#roadmap #milestones h4 {
  margin-bottom: 30px;
  font-family: 'Carnivalee_Freakshow';
  text-transform: lowercase;
  font-weight: normal;
  font-size: 36px;
  color: #fff;
}
#roadmap #milestones .circle {
  width: 36px;
  height: 36px;
  background: #000000;
  border: 9px solid #39213a;
  margin: auto;
  margin-bottom: 30px;
  border-radius: 50%;
}

#monster_wall {
  position: relative;
  margin-top: 140px;
}
#monster_wall .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.2);
  background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

#line {
  position: absolute;
  top: 337px;
  left: 0;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  #roadmap #milestones {
    grid-column-gap: 0;
  }
  #roadmap h2 {
    font-size: 63px !important;
  }
  #roadmap h3 {
    font-family: 'Roboto', sans-serif !important;
    font-size: 17px !important;
    text-transform: none;
  }
  #roadmap .img_wrapper {
    display: block;
    margin-bottom: 0;
  }
  #line {
    display: none;
  }
  #roadmap #milestones > div {
    margin: 0;
    margin-bottom: 40px;
  }
  #roadmap #milestones > div:last-child {
    margin-bottom: 0;
  }
  #roadmap #milestones .circle {
    width: 24px;
    height: 24px;
    border-width: 5px;
    position: relative;
    top: -12px;
    margin-bottom: 10px;
  }
  #roadmap #milestones strong {
    font-size: 50px;
    margin-bottom: 14px;
  }
  #roadmap #milestones h4 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  #monster_wall {
    margin-top: 60px;
    overflow: hidden;
  }
  #monster_wall img {
    max-width: 300%;
    position: relative;
    left: -30%;
    min-height: 0;
  }
}
