.token_wrapper {
  padding: 120px 30px 30px;
  width: 100%;
  max-width: 1140px;
  margin: 50px auto 0;
}
.token_wrapper h1 {
  font-family: 'Carnivalee_Freakshow';
  font-size: 55px;
  text-align: center;
  font-weight: normal;
  color: #fff;
  line-height: 0.82;
  text-align: left;
  width: 100%;
  text-transform: lowercase;
}
.token_wrapper h2 {
  font-family: 'Carnivalee_Freakshow';
  font-size: 30px;
  font-weight: normal;
  color: #fff;
  text-transform: lowercase;
}

.token_wrapper .grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.token_wrapper .col_6 {
  grid-column: span 6;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.token_wrapper .col_6:last-child {
  grid-column: span 6;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.token_wrapper .heading_properties {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  align-items: center;
  width: 100%;
  margin: 24px 0 20px;
  padding-top: 24px;
}

.token_wrapper .heading_properties a {
  font-size: 14px;
  font-style: italic;
  padding-bottom: 2px;
  border-bottom: 1px solid #fff;
}

.token_wrapper .heading_properties a:hover {
  opacity: 0.8;
}

.token_wrapper .data_wrapper {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.token_wrapper .img_wrapper {
  max-width: 500px;
  padding: 25px;
  background-color: #1e111e;
  border-radius: 10px;
}

.token_wrapper ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.token_wrapper li {
  grid-column: span 6;
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 15px;
}

.token_wrapper li h3 {
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 10px;
}

.token_wrapper li p {
  font-size: 15px;
}

.token_wrapper .go_to_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
}
.token_wrapper .go_to_profile a:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1140px) {
  .token_wrapper h1 {
    font-size: 45px;
  }
}

@media screen and (max-width: 960px) {
  .token_wrapper .col_6 {
    grid-column: span 12;
    justify-content: center;
    align-items: center;
  }

  .token_wrapper h1 {
    font-size: 40px;
  }
  .token_wrapper h2 {
    font-size: 20px;
  }

  .token_wrapper .data_wrapper {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .token_wrapper .grid {
    grid-column-gap: 0px;
  }

  .token_wrapper .col_6 {
    grid-column: span 12;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .token_wrapper .col_6:last-child {
    grid-column: span 12;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .token_wrapper ul {
    grid-column-gap: 15px;
    grid-row-gap: 9px;
  }
}
