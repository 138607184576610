.heading_profile {
  font-family: 'Carnivalee_Freakshow';
  text-align: center;
  font-size: 50px;
  margin: 100px 0 20px;
  color: #fff;
}

.loading_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  text-align: center;
}

.loading_wrapper img {
  width: 50px;
}

@media screen and (min-width: 992px) {
  .ownedItemWrapper {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .ownedItemWrapper .dropWrapper {
    margin-left: 30px;
  }
}
