.dropWrapper {
  border-radius: 10px;
  background: #1e111e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px;
}

@media (min-width: 419px) {
  .dropWrapper {
    max-width: 420px;
  }
}

#dropImageCard {
  width: 100%;
}

#dropImageCard img {
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
