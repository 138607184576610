.btn {
  background: none;
  border: 2px solid #aaa;
  border-radius: 50px;
  font-size: 17px;
  color: #fff;
  padding: 10px 34px;
  /* margin-left: 30px; */
  margin: 0 auto;
  cursor: pointer;
}
.btn:hover {
  background: #000;
  border-color: #fff;
}

.btn_connected {
  display: inline;
  background: none;
  border: 2px solid #aaa;
  border-radius: 50px;
  font-size: 17px;
  color: #fff;
  padding: 10px 20px 10px 10px !important;
  /* margin-left: 30px; */
  margin: 0 auto;
}

.profile {
  margin-right: 10px;
  font-size: 14px;
}

.btn_connected span {
  display: inline-block;
  font-size: 15px;
  margin-right: 14px !important;
}

.btn_connected img {
  width: 7px;
}

.btn_connected button {
  position: relative;
}

.btn_connected a {
  margin-right: 0 !important;
}

.btn_disconnect {
  background-color: #9a0772;
  border-radius: 60px;
  border: 0;
  color: #ffffff;
  width: 18px;
  height: 18px;
  padding: 0;

  position: relative;
  top: -1px;
  text-align: center;
  line-height: 18px;
}

.btn_disconnect {
  vertical-align: middle;
}

.btn_disconnect:hover {
  opacity: 0.6;
  cursor: pointer;
}
.btn_disconnect img {
  position: relative !important;
  top: 0 !important;
  height: 9px !important;
  margin: 0 auto !important;
  display: block;
}

@media screen and (max-width: 1100px) {
  .btn_connected span {
    display: inline-block;
    margin-right: 10px;
    font-size: 13px;
  }

  .profile {
    transform: translateY(1px);
  }

  .btn_connected {
    padding: 5px 15px 10px 5px !important;
    margin-left: 0;
    position: relative;
    top: 7px;
    left: -10px;
  }
}
