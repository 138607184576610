/* Header */

#header {
  height: 100px;
  padding: 10px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.2s;
  background: none;
}

#header nav {
  display: flex;
  align-items: center;
}
#header nav a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
}
#header nav a:hover {
  color: #ccc;
  transition: all 0.2s;
}

#header nav li {
  display: inline-block;
  margin-right: 36px;
}
#header .actions a {
  display: inline-block;
  margin-left: 14px;
}

#header .actions a {
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin-right: 20px;
}
#header .actions a svg {
  margin-right: 10px;
}

#header .actions img {
  height: 18px;
  position: relative;
  top: 3px;
  opacity: 1;
  transition: all 0.2s;
}
#header .actions img:hover {
  opacity: 0.8;
  transition: all 0.2s;
}
#header .actions .btn {
  background: none;
  border: 2px solid #aaa;
  border-radius: 50px;
  font-size: 17px;
  color: #fff;
  padding: 10px 34px;
  margin-left: 30px;
  display: none;
}
#header .actions .btn:hover {
  background: #000;
  border-color: #fff;
}

#header #isodesktop {
  position: relative;
  top: -4px;
}
#header #isodesktop img {
  width: 60px;
  margin-right: 40px;
}
#header #isodesktop:hover {
  transform: rotate(-10deg);
}

#menu_mobile {
  width: 100%;
  height: 100%;
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 10000;
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 0 30px;
  box-shadow: -3px 0 7px rgba(0, 0, 0, 0.1);
  background: #000;
  transition: all 0.2s;
}
#menu_mobile.active {
  right: 0;
}
#menu_mobile #close_menu {
  position: absolute;
  top: 22px;
  right: 30px;
  width: 20px;
  cursor: pointer;
}
#menu_mobile li a {
  font-size: 40px;
  text-transform: lowercase;
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid #333;
  text-align: center;
  opacity: 1;
  color: #fff !important;
  font-family: 'Carnivalee_Freakshow';
}
#menu_mobile li:last-child a {
  padding-bottom: 0;
  border: 0;
}
#menu_mobile li a:hover {
  opacity: 0.8 !important;
  transition: all 0.2s;
}

@media screen and (max-width: 1200px) {
  /* #header .actions a:not([class*='profile']) {
    display: none;
  } */
}

@media screen and (max-width: 1100px) {
  #header nav {
    display: none;
  }
  #header {
    display: block;
    text-align: center;
    padding: 14px 30px;
    height: auto !important;
    height: 70px !important;
    z-index: 2;
  }
  #header button {
    margin: 0 !important;
    font-size: 13px;
  }

  #header .actions {
    position: relative;
  }
  #header .actions a {
    margin-right: 0 !important;
  }
  #header .actions > a {
    position: absolute;
    top: 10px;
    right: 30px;
  }
  #header .actions > a span {
    display: none;
  }

  #header > a,
  #header > img {
    display: block !important;
  }

  #header .actions .btn {
    padding: 8px 26px;
    font-size: 14px;
  }
  #header > a:first-child {
    position: absolute;
    left: 24px;
    top: 10px;
    width: 45px;
  }
  #header > .icon_close {
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
  }
  #menu_btn {
    position: absolute;
    right: 30px;
    top: 6px;
  }
}
