a {
  text-decoration: none;
  color: #ffffff;
}

#btnDark {
  background-color: #ff0000 !important;
  border: 1px solid #333333 !important;
  cursor: pointer;
}

#btnDark a {
  color: #ffffff;
  cursor: pointer;
}

#btnLight {
  background: transparent;
  color: #fff;
  font-size: 17px;
  display: inline-block;
  border: 2px solid #ff0000;
  border-radius: 35px;
  text-align: center;
  padding: 15px 25px;
  min-width: 210px;
  cursor: pointer;
}

#btnLight a {
  color: #fff;
}
