.loading_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
  text-align: center;
}

.loading_wrapper img {
  width: 50px;
}
